import { render, staticRenderFns } from "./BaseAntScanner.vue?vue&type=template&id=92987ad4&scoped=true"
import script from "./BaseAntScanner.vue?vue&type=script&lang=js"
export * from "./BaseAntScanner.vue?vue&type=script&lang=js"
import style0 from "./BaseAntScanner.vue?vue&type=style&index=0&id=92987ad4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92987ad4",
  null
  
)

export default component.exports